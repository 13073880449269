<template>
	<div class="create-surgeon">
		<v-dialog v-model="isDialogVisible" persistent width="640px">
			<v-card>
				<v-card-title class="amber"><span class="headline">Create Surgeon</span></v-card-title>
				<v-card-text>
					<v-form ref="form" v-model="valid">
						<v-container>
							<v-row>
								<v-col sm="6">
									<v-select
										:items="caseTypes"
										v-model="caseType"
										:rules="caseTypeRules"
										required
										label="Case Type"
									></v-select>
								</v-col>
							</v-row>
							<v-row v-if="this.caseType === 'TKA'">
								<v-col sm="6">
									<v-text-field v-model="firstName" :rules="firstNameRules" required label="First Name"></v-text-field>
								</v-col>
								<v-col sm="6">
									<v-text-field v-model="lastName" :rules="lastNameRules" required label="Last Name"></v-text-field>
								</v-col>
								<v-col sm="12">
									<v-text-field
										v-model="displayName"
										:rules="displayNameRules"
										required
										readonly
										label="Display Name"
									></v-text-field>
								</v-col>
								<v-col sm="6">
									<v-select
										:items="insertTypes"
										v-model="insertType"
										:rules="insertTypeRules"
										required
										label="Insert Type"
									></v-select>
								</v-col>
								<v-col sm="6">
									<v-select
										:items="basePlates"
										v-model="basePlate"
										:rules="basePlateRules"
										required
										label="Base Plate"
									></v-select>
								</v-col>
							</v-row>
							<v-row v-if="this.caseType === 'THA'">
								<v-col sm="6">
									<v-text-field v-model="firstName" :rules="firstNameRules" required label="First Name"></v-text-field>
								</v-col>
								<v-col sm="6">
									<v-text-field v-model="lastName" :rules="lastNameRules" required label="Last Name"></v-text-field>
								</v-col>
								<v-col sm="12">
									<v-text-field
										v-model="displayName"
										:rules="displayNameRules"
										required
										readonly
										label="Display Name"
									></v-text-field>
								</v-col>
								<v-col sm="6">
									<v-select
										:items="approachTypes"
										v-model="approachType"
										:rules="approachRules"
										required
										label="Approach"
									></v-select>
								</v-col>
								<v-col sm="6">
									<v-select
										:items="acetabularComponentTypes"
										v-model="acetabularComponentType"
										:rules="acetabularComponentTypeRules"
										required
										label="Acetabular Component"
									></v-select>
								</v-col>
								<v-col sm="6">
									<v-select
										:items="femoralComponentTypes"
										v-model="femoralComponentType"
										:rules="femoralComponentTypeRules"
										required
										label="Femoral Component"
									></v-select>
								</v-col>
							</v-row>
						</v-container>
					</v-form>
				</v-card-text>
				<v-divider></v-divider>
				<v-card-actions class="mx-2">
					<v-spacer></v-spacer>
					<v-btn text @click="onCancel">Cancel</v-btn>
					<v-btn text color="warning" :disabled="!valid" @click="onCreate">Create</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>

<script>
	import { mapActions } from 'vuex';
	export default {
		name: 'create-surgeon',
		props: {
			showDialog: {
				type: Boolean,
				default: false,
			},
			surgeons: {
				type: Array,
				require: false,
			},
		},
		data() {
			return {
				valid: false,
				search: null,
				caseType: '',
				caseTypeRules: [(v) => !!v || 'Case type is required'],
				caseTypes: ['TKA', 'THA'],
				firstName: '',
				firstNameRules: [(v) => !!v || 'First name is required'],
				lastName: '',
				lastNameRules: [(v) => !!v || 'Last name is required'],
				displayNameRules: [(v) => (v && !this.checkDisplayName(v)) || 'A profile for this surgeon already exists'],
				insertType: '',
				insertTypeRules: [(v) => !!v || 'Insert type is required'],
				insertTypes: ['CR', 'CS', 'PS', 'TS'],
				basePlate: '',
				basePlateRules: [(v) => !!v || 'Base plate is required'],
				basePlates: ['All Poly', 'Cruciform', 'Tritanium', 'Universal'],
				approachType: '',
				approachRules: [(v) => !!v || 'Approach is required'],
				approachTypes: ['Postero-Lateral', 'Antero-Lateral', 'Direct Anterior', 'Direct Superior (THA 4.0 only)'],
				acetabularComponentType: '',
				acetabularComponentTypeRules: [(v) => !!v || 'Acetabular component is required'],
				acetabularComponentTypes: [
					'Trident Hemispherical',
					'Trident PSL',
					'Trident Tritanium',
					'Trident II Hemispherical (THA 4.0 only)',
					'Trident II PSL (THA 4.0 only)',
					'Trident II Tritanium',
				],
				femoralComponentType: '',
				femoralComponentTypeRules: [(v) => !!v || 'Femoral component is required'],
				femoralComponentTypes: ['Accolade II', 'Secur-Fit Advanced', 'Anato', 'Exeter', 'Exeter US', 'Insignia'],
			};
		},
		computed: {
			isDialogVisible: {
				get() {
					return this.showDialog;
				},
				set(value) {
					this.$emit('dialog-state-change', value);
				},
			},
			displayName: {
				get() {
					return (
						this.lastName.charAt(0).toUpperCase() +
						this.lastName.slice(1).toLowerCase() +
						', ' +
						this.firstName.charAt(0).toUpperCase() +
						this.firstName.slice(1).toLowerCase()
					);
				},
				set() {},
			},
		},

		methods: {
			...mapActions('surgeons', { setSurgeons: 'setSurgeons', setSurgeon: 'setSurgeon' }),
			async onCreate() {
				let data = {
					surgeonType: this.caseType,
					firstName: this.firstName.charAt(0).toUpperCase() + this.firstName.slice(1).toLowerCase(),
					lastName: this.lastName.charAt(0).toUpperCase() + this.lastName.slice(1).toLowerCase(),
					displayName: this.displayName,
					createdBy: this.$store.state.accounts.account.username,
					createdDate: this.$moment().utc(),
				};
				if (this.caseType === 'TKA') {
					data['insertType'] = this.insertType;
					data['basePlate'] = this.basePlate;
				}
				if (this.caseType === 'THA') {
					data['approach'] = this.approachType;
					data['acetabularComponentType'] = this.acetabularComponentType;
					data['femoralComponentType'] = this.femoralComponentType;
				}

				this.surgeon = await this.setSurgeon(data);
				this.onCancel();
			},

			onCancel() {
				this.$refs.form.reset();
				this.isDialogVisible = false;
			},

			checkDisplayName() {
				return this.surgeons.some(
					(surgeon) =>
						surgeon.displayName.toLowerCase() === this.displayName.toLowerCase() &&
						surgeon.surgeonType === this.caseType
				);
			},
		},
		watch: {},
	};
</script>

<style></style>
