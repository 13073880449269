<template>
  <div class="surgeons">
    <v-row>
      <v-col sm="12">
        <span class="headline">{{ pageName }}</span>
      </v-col>
    </v-row>
    <v-row dense>
      <v-col sm="2">
        <v-switch v-model="procedureType" label="THA"></v-switch>
      </v-col>
      <v-col v-if="!procedureType" sm="2">
        <v-select v-model="insertTypeFilterValue" :items="insertTypes" label="Insert Type" clearable></v-select>
      </v-col>
      <v-col v-if="!procedureType" sm="2">
        <v-select v-model="basePlateTypeFilterValue" :items="basePlateTypes" label="Base Plate" clearable></v-select>
      </v-col>
      <v-col v-if="procedureType" sm="2">
        <v-select v-model="approachTypeFilterValue" :items="approachTypes" label="Approach" clearable></v-select>
      </v-col>
      <v-col v-if="procedureType" sm="2">
        <v-select
          v-model="acetabularComponentTypeFilterValue"
          :items="acetabularComponentTypes"
          label="Acetabular Component"
          clearable
        ></v-select>
      </v-col>
      <v-col v-if="procedureType" sm="2">
        <v-select
          v-model="femoralComponentTypeFilterValue"
          :items="femoralComponentTypes"
          label="Femoral Component"
          clearable
        ></v-select>
      </v-col>
      <v-col sm="3" class="ml-auto">
        <v-text-field v-model="search" append-icon="mdi-magnify" label="Search this list..." single-line></v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col sm="12">
        <v-data-table
          :headers="headers"
          :items="filteredSurgeons"
          :loading="loading"
          :search="search"
          :items-per-page="5"
          class="elevation-1"
        >
          <template v-slot:[`item.surgeonType`]="{ item }">
            <v-chip v-if="item.surgeonType == 'TKA'" color="purple" small text-color="white">{{
              item.surgeonType
            }}</v-chip>
            <v-chip v-if="item.surgeonType == 'THA'" color="teal" small text-color="white">{{
              item.surgeonType
            }}</v-chip>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <v-icon small class="mr-2" @click="onEditSurgeon(item)">mdi-pencil</v-icon>
            <v-icon small @click="onDeleteSurgeon(item)">mdi-delete</v-icon>
          </template>
        </v-data-table>
      </v-col>
    </v-row>

    <v-btn bottom color="amber" fab fixed right @click="onCreateSurgeon">
      <v-icon>mdi-plus</v-icon>
    </v-btn>
    <CreateSurgeon
      :showDialog="dialog"
      :surgeons="surgeons"
      @dialog-state-change="onDialogStateChange"
      :key="componentKey"
    />
  </div>
</template>

<script>
import axios from 'axios';
import { mapGetters, mapActions } from 'vuex';
import CreateSurgeon from '@/components/CreateSurgeon.vue';
export default {
  name: 'surgeons',
  components: {
    CreateSurgeon,
  },
  data() {
    return {
      pageName: 'Surgeon Profiles',
      componentKey: 0,
      dialog: false,
      search: null,
      procedureType: false,
      procedureTypes: ['TKA', 'THA'],
      insertTypeFilterValue: '',
      basePlateTypeFilterValue: '',
      approachTypeFilterValue: '',
      acetabularComponentTypeFilterValue: '',
      femoralComponentTypeFilterValue: '',
    };
  },
  computed: {
    ...mapGetters('surgeons', { surgeons: 'getSurgeons', loading: 'getLoading' }),
    headers() {
      if (this.procedureType) {
        return [
          {
            text: 'Display Name',
            value: 'displayName',
          },
          {
            text: 'Procedure Type',
            value: 'surgeonType',
            sortable: false,
          },
          {
            text: 'Approach',
            value: 'approach',
            filter: this.approachTypeFilter,
          },
          {
            text: 'Acetabular component',
            value: 'acetabularComponentType',
            filter: this.acetabularComponentTypeFilter,
          },
          {
            text: 'Femoral component',
            value: 'femoralComponentType',
            filter: this.femoralComponentTypeFilter,
          },
          // {
          //   text: 'Actions',
          //   value: 'actions',
          //   sortable: false,
          // },
        ];
      } else {
        return [
          {
            text: 'Display Name',
            value: 'displayName',
          },
          {
            text: 'Procedure Type',
            value: 'surgeonType',
            sortable: false,
          },
          {
            text: 'Insert Type',
            value: 'insertType',
            filter: this.insertTypeFilter,
          },
          {
            text: 'Base Plate',
            value: 'basePlate',
            filter: this.basePlateTypeFilter,
          },
          // {
          //   text: 'Actions',
          //   value: 'actions',
          //   sortable: false,
          // },
        ];
      }
    },
    insertTypes() {
      const type = this.filteredSurgeons.map((item) => item.insertType);
      return type;
    },
    basePlateTypes() {
      const type = this.filteredSurgeons.map((item) => item.basePlate);
      return type;
    },
    approachTypes() {
      const type = this.filteredSurgeons.map((item) => item.approach);
      return type;
    },
    acetabularComponentTypes() {
      const type = this.filteredSurgeons.map((item) => item.acetabularComponentType);
      return type;
    },
    femoralComponentTypes() {
      const type = this.filteredSurgeons.map((item) => item.femoralComponentType);
      return type;
    },
    filteredSurgeons() {
      if (this.procedureType) {
        return this.surgeons.filter((surgeon) => surgeon.surgeonType == 'THA');
      } else {
        return this.surgeons.filter((surgeon) => surgeon.surgeonType == 'TKA');
      }
    },
  },
  created() {
    this.setSurgeons();
  },
  methods: {
    ...mapActions('surgeons', { setSurgeons: 'setSurgeons' }),
    onDialogStateChange(state) {
      this.dialog = state;
    },
    forceRerender() {
      this.componentKey += 1;
    },
    onCreateSurgeon() {
      this.dialog = true;
    },
    onDeleteSurgeon(surgeon) {
      axios.post('https://func-gcas-dev.azurewebsites.net/api/deleteSurgeon', surgeon).then((res) => {
        console.log('Delete surgeon response: ', res);
        this.setSurgeons();
      });
    },
    // Datatable filters
    insertTypeFilter(value) {
      if (!this.insertTypeFilterValue) {
        return true;
      }

      return value === this.insertTypeFilterValue;
    },
    basePlateTypeFilter(value) {
      if (!this.basePlateTypeFilterValue) {
        return true;
      }

      return value === this.basePlateTypeFilterValue;
    },
    approachTypeFilter(value) {
      if (!this.approachTypeFilterValue) {
        return true;
      }

      return value === this.approachTypeFilterValue;
    },
    acetabularComponentTypeFilter(value) {
      if (!this.acetabularComponentTypeFilterValue) {
        return true;
      }

      return value === this.acetabularComponentTypeFilterValue;
    },
    femoralComponentTypeFilter(value) {
      if (!this.femoralComponentTypeFilterValue) {
        return true;
      }

      return value === this.femoralComponentTypeFilterValue;
    },
    isSystemAdmin() {
      return this.$store.state.accounts.currentAccount.role === 'System Admin';
    },
  },
  watch: {
    dialog: function(newValue) {
      if (!newValue) {
        console.log('Dialog Rerender: ', { newValue: newValue });
        this.forceRerender();
      }
    },
  },
};
</script>
